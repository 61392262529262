<template>
  <b-card
    no-body
    class="system-settings-card"
  >
    <!-- START: Table -->
    <div class="m-2">
      <b-table
        ref="refTable"
        :busy="loading"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        class="table-responsive"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head()="{ label }">
          <span style="white-space: nowrap">{{ $t(label) }}</span>
        </template>

        <template #cell(startTimestamp)="{ item }">
          {{ item.startTimestamp | moment('MMM DD, YYYY') }}
        </template>

        <template #cell(duration)="{ item }"> {{ item.duration }}s </template>
      </b-table>
    </div>
    <!-- END: Table -->

    <!-- START: Pagination -->
    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handleCurrentPage"
    />
    <!-- END: Pagination -->
  </b-card>
</template>

<script>
import webhooksLogs from '@/services/webhooksLogs'
import listMixinFactory from '@/mixins/list'
import { title } from '@/@core/utils/filter'
import momentFilterMixin from '@/mixins/momentFilter'

export default {
  name: 'WebhookLogsIntegrations',
  mixins: [
    listMixinFactory({
      routeName: 'integrations-settings',
      service: webhooksLogs,
      tableColumns: [
        { key: 'uri', label: 'URL Called', sortable: true },
        { key: 'startTimestamp', label: 'Start Timestamp', sortable: true },
        { key: 'duration', label: 'Duration', class: ' disabled' },
        { key: 'httpMethod', label: 'HTTP Method', class: 'disabled' },
        {
          key: 'httpStatus',
          label: 'HTTP response status code',
          class: 'disabled',
        },
      ],
      searchField: 'name',
      sort: {
        name: 'name',
        label: 'label',
      },
    }),

    momentFilterMixin,
  ],

  data: () => ({
    title,
  }),

  methods: {},
}
</script>

<style></style>
