<template>
  <b-modal
    id="modal-center"
    centered
    hide-footer
    no-fade
    no-close-on-backdrop
    no-close-on-esc
    hide-header-close
    hide-header
    size="lg"
    :visible="visible"
  >
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      rounded="sm"
      variant="transparent"
    >
      <form
        autocomplete="off"
        @submit.prevent="save"
      >
        <div class="form-wrapper">
          <div class="form-wrapper__header">
            <feather-icon
              icon="PlusIcon"
              size="19"
              class="tw-mr-3"
            />
            {{ $t(formTitle) }}
          </div>
          <div class="form-wrapper__content">
            <b-container fluid>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <prozess-input
                    v-model="form.name"
                    :placeholder="$t('Name')"
                    icon="TypeIcon"
                    field="name"
                    name="name"
                    :error="$hasError('name')"
                    @enter="save"
                  />

                  <prozess-input
                    v-model="form.webhookUrl"
                    :placeholder="$t('URL')"
                    icon="GlobeIcon"
                    field="webhookUrl"
                    name="webhookUrl"
                    :error="$hasError('webhookUrl')"
                    @enter="save"
                  />
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-textarea
                    v-model="form.description"
                    class="tw-mt-2"
                    :placeholder="$t('Description')"
                    rows="4"
                  />
                  <span
                    v-if="$hasError('description')"
                    class="invalid-feedback"
                  >
                    {{ $t($hasError('description')) }}
                  </span>
                </b-col>
              </b-row>
            </b-container>

            <!-- START: ENTITY -->
            <div class="form-wrapper__content__table">
              <table
                width="100%"
                class="table rounded-table"
              >
                <thead>
                  <th>
                    <div class="row-flex-align-center font-base">
                      <b-form-checkbox
                        v-model="allSelected"
                        @change="handleAllSelectEntity"
                      />
                      <span>{{ $t('Entity') }} </span>
                    </div>
                  </th>
                  <th
                    v-for="action in actions"
                    :key="action.text"
                  >
                    <div class="row-flex-align-center font-title">
                      <b-form-checkbox
                        v-model="action.isAll"
                        @change="handleAllActionSelect($event, action.text)"
                      />
                      <span> {{ $t(action.text) }}</span>
                    </div>
                  </th>
                </thead>
                <tbody>
                  <tr
                    v-for="entity in form.entities"
                    :key="entity.tableKey"
                  >
                    <td>
                      <div class="row-flex-align-center">
                        <b-form-checkbox
                          v-model="entity.entitySelected"
                          @change="handleSelectRow($event, entity)"
                        />
                        <span>{{ $t(entity.label) }}</span>
                      </div>
                    </td>
                    <td
                      v-for="(action, actionIndex) in actions"
                      :key="action.text"
                    >
                      <b-checkbox
                        v-model="entity.actions[actionIndex].value"
                        @change="handleIndividualAction($event, entity, entity.actions[actionIndex].text)"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <span
              v-if="$hasError('entities')"
              class="invalid-feedback"
            >
              {{ $t($hasError('entities')) }}
            </span>
            <!-- END: ENTITY -->
            <!-- START: HEADER -->
            <div class="form-wrapper__headers-webhook">
              <span class="font-title font-weight-bold">{{ $t('Headers') }}</span>
              <div class="tw-mt-4 form-wrapper__headers-webhook__content">
                <div
                  v-for="(header, index) in form.headers"
                  :key="index"
                  class="fields row-flex-align-baseline"
                >
                  <prozess-input
                    v-model="header.key"
                    class="form-wrapper__headers-webhook__content--input"
                    :placeholder="$t('Name')"
                    icon="TypeIcon"
                    :field="`headers[${index}].key`"
                    :name="`headers[${index}].key`"
                    :error="$hasError(`headers[${index}].key`)"
                    @enter="save"
                  />

                  <prozess-input
                    v-model="header.value"
                    class="form-wrapper__headers-webhook__content--input"
                    :placeholder="$t('Value')"
                    icon="HashIcon"
                    :field="`headers[${index}].value`"
                    :name="`headers[${index}].value`"
                    :error="$hasError(`headers[${index}].value`)"
                    @enter="save"
                  />

                  <feather-icon
                    :id="`delete-header-${index}`"
                    icon="Trash2Icon"
                    class="tw-mx-2"
                    :class="form.headers.length <= 1 ? 'text-dark' : 'cursor-pointer'"
                    @click="deleteHeader(index)"
                  />

                  <b-tooltip
                    v-if="form.headers.length > 1"
                    placement="left"
                    :title="$t('Delete header')"
                    :target="`delete-header-${index}`"
                  />
                </div>
              </div>

              <div class="row-flex-justify-end">
                <b-button
                  id="create-button"
                  class="tw-mr-4"
                  variant="primary"
                  @click="addHeader"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">{{ $t('Add Header') }}</span>
                </b-button>
              </div>
            </div>

            <!-- END: HEADER -->
          </div>
          <div class="form-wrapper__action">
            <b-button
              variant="outline-primary"
              class="tw-mr-4"
              @click="close"
            >
              <span class="align-middle">{{ $t('Cancel') }}</span>
            </b-button>
            <b-button
              class="mr-1"
              variant="primary"
              :disabled="saving"
              type="submit"
            >
              <span>{{ $t('Save') }}</span>
            </b-button>
          </div>
        </div>
      </form>
    </b-overlay>
  </b-modal>
</template>

<script>
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import ProzessInput from '@core/components/ProzessInput.vue'
import { actions } from '@/constants/webHooks'
import { webhookSchema } from '@/schema/webhooks'
import webhooksService from '@/services/webhooks'

const initialForm = {
  name: '',
  description: '',
  webhookUrl: '',
  entityAssignment: [],
  headers: [
    {
      key: '',
      value: '',
    },
  ],
}
export default {
  name: 'WebhookForm',
  components: { FeatherIcon, ProzessInput },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      form: this.$lodash.cloneDeep(initialForm),
      loading: false,
      saving: false,
      version: 0,
      actions: this.$lodash.cloneDeep(actions),
      entities: [],
      toDeleteHeaders: [],
      initialEntityValue: [],
      errors: [],
      allSelected: false,
    }
  },

  computed: {
    formIcon() {
      return this.resourceId ? 'EditIcon' : 'PlusIcon'
    },

    formTitle() {
      return this.resourceId ? 'Edit Webhook' : 'Create New Webhook'
    },
  },

  watch: {
    visible: {
      handler(visible) {
        if (visible) {
          this.resetForm()
          this.getInitialData()
          this.resetToInitialEntity()
        }
      },
    },
  },

  created() {
    this.fetchEntities()
  },

  methods: {
    close() {
      this.resetForm()
      this.resetToInitialEntity()
      this.$emit('close')
    },

    async getInitialData() {
      if (!this.resourceId) return false
      this.loading = true
      if (!this.entities.length) this.fetchEntities()
      const { response } = await this.$async(webhooksService.getOne(this.resourceId))
      const { name, version, description, headers, assignedTo, url } = response.data

      // START: setting up headers

      const formHeaders = []

      Object.entries(headers).forEach(([key, value]) => {
        formHeaders.push({ key, value })
        this.toDeleteHeaders.push(key)
      })

      // END: setting up headers

      // START: setting up of entity

      assignedTo.forEach(entity => {
        const findEntitiesIndex = this.form.entities.findIndex(givenEntity => givenEntity.tableKey === entity.tableKey)

        const targetEntity = this.form.entities[findEntitiesIndex]
        if (targetEntity) {
          targetEntity.id = entity.id
          // START: handle entity row select

          if (actions.length === entity.actions.length) {
            targetEntity.entitySelected = true
          }
          entity.actions.forEach(action => {
            targetEntity.actions.forEach((formAction, index) => {
              if (formAction.formVal === action) {
                targetEntity.actions[index].value = true
              }
            })
          })
          // END: handle entity row select
        }
      })

      // END: Setting up of entity

      const newform = {
        ...this.form,
        name,
        description,
        webhookUrl: url,
        headers: formHeaders.length ? formHeaders : [{ key: '', value: '' }],
      }

      this.version = version

      this.initialEntityValue = this.$lodash.cloneDeep(newform.entities)

      this.form = newform

      this.loading = false
    },

    resetForm() {
      this.allSelected = false
      this.errors = []
      this.form = this.$lodash.cloneDeep(initialForm)
    },

    handleIndividualAction(value, entity, action) {
      if (!value) {
        const entityIndex = this.form.entities.findIndex(({ tableKey }) => tableKey === entity.tableKey)
        const actionIndex = this.actions.findIndex(({ text }) => text === action)
        this.allSelected = false
        this.actions[actionIndex].isAll = false
        this.form.entities[entityIndex].entitySelected = false
      }
      this.$forceUpdate()
    },

    handleAllActionSelect(val, action) {
      this.form.entities = this.form.entities.map(entity => {
        const actionsSelected = entity.actions.map(ent => ({
          ...ent,
          value: ent.text === action ? val : ent.value,
        }))
        return {
          ...entity,
          actions: actionsSelected,
        }
      })

      this.$forceUpdate()
    },

    handleSelectRow(val, entity) {
      const entityIndex = this.form.entities.findIndex(({ tableKey }) => tableKey === entity.tableKey)
      if (entityIndex === -1) return

      this.form.entities[entityIndex].actions = this.actions.map(action => ({
        ...action,
        value: !!val,
      }))

      this.$forceUpdate()
    },
    handleAllSelectEntity(val) {
      this.actions = this.actions.map(action => ({
        ...action,
        isAll: !!val,
      }))
      this.form.entities = this.form.entities.map(entity => ({
        ...entity,
        entitySelected: !!val,
        actions: this.actions.map(action => ({
          ...action,
          value: !!val,
        })),
      }))

      this.$forceUpdate()
    },

    addHeader() {
      this.form.headers.push({
        key: '',
        value: '',
      })
    },

    deleteHeader(index) {
      if (this.form.headers.length <= 1) return false
      this.form.headers = this.form.headers.filter((header, headerIndex) => headerIndex !== index)
    },

    async fetchEntities() {
      this.loading = true
      const { response } = await this.$async(webhooksService.getAllEntities())
      this.entities = response?.data

      this.resetToInitialEntity()

      this.loading = false
    },

    resetToInitialEntity() {
      this.form.entities = this.entities.map(({ schemaKey, tableKey, label }) => ({
        schemaKey,
        entitySelected: false,
        tableKey,
        label,
        actions: this.$lodash.cloneDeep(this.actions),
      }))

      this.actions = this.$lodash.cloneDeep(actions)
    },

    async save() {
      const form = this.$lodash.cloneDeep(this.form)

      const entityAssignment = this.transformEntityToPayload(form.entities)
      const headers = form.headers.filter(header => header.key && header.value)

      this.errors = await this.yupValidate(webhookSchema, {
        ...this.form,
        entities: entityAssignment,
      })

      if (!this.errors.length) {
        this.saving = true

        // *this removes any key before new propery: for this i named it newForm , everything before newForm will be removed
        const { entities, ...newForm } = form

        const response = await this.$async(this.createOrUpdate({ ...newForm, entityAssignment, headers }))

        const errors = response.errors
        const error = response.error

        const exception = this.$lodash.get(error, 'response.data.message')

        if (exception?.includes('Version change')) {
          this.saving = false
          this.resetForm()
          this.close()
          return this.showWarning('This record is outdated, please reopen the form to get the latest update')
        }

        if (errors) {
          this.errors = this.$loadErrors(errors)
        } else {
          this.$emit('saved')
          this.resetForm()
          this.close()
        }

        this.saving = false
      }
    },

    transformEntityToPayload(entities) {
      return entities
        .map(entity => {
          const filteredActions = entity.actions.filter(action => action.value).map(act => act.formVal.toUpperCase())

          return {
            id: entity.id ?? undefined,
            schemaKey: entity.schemaKey,
            tableKey: entity.tableKey,
            actions: filteredActions,
          }
        })
        .filter(entityFilter => entityFilter.actions.length)
    },

    createOrUpdate(form) {
      const { entityAssignment, headers, ...updateForm } = form

      if (this.resourceId) {
        const originalEntity = this.initialEntityValue.filter(entity => entity.id)
        const existingEntity = this.form.entities.filter(entity => entity.id)

        const deleteAssignments = existingEntity
          .filter(entityFilter => {
            const isDeleted = entityFilter.actions.every(action => !action.value)
            return isDeleted
          })
          .map(deletedId => deletedId.id)

        const newAssignments = entityAssignment.filter(newEntity => !newEntity.id)

        const modifyAssignments = this.transformEntityToPayload(this.$lodash.differenceWith(existingEntity, originalEntity, this.$lodash.isEqual))

        const updateFormPayload = {
          deleteHeaders: this.toDeleteHeaders,
          ...updateForm,
          addHeaders: headers,
          deleteAssignments: deleteAssignments.length ? deleteAssignments : undefined,
          newAssignments: newAssignments.length ? newAssignments : undefined,
          modifyAssignments: modifyAssignments.length ? modifyAssignments : undefined,
          version: this.version,
        }

        return webhooksService.update(this.resourceId, updateFormPayload)
      }

      return webhooksService.store(form)
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/master-variables.scss';
#modal-center {
  border-radius: 15px !important;
  .form-wrapper {
    min-height: 600px;
    min-height: 80%;
    display: flex;
    padding: 10px;
    flex-direction: column;
    border-radius: 10px;
    &__header {
      display: flex;
      font-size: 1.3rem;
      align-items: center;
      border-bottom: 1px solid var(--colour--input-border);
      padding-bottom: 10px;
    }
    &__content {
      &__table {
        margin-top: 10px;
        background: var(--colour--table-row);
        height: 250px;
        overflow-y: auto;
        border-radius: 10px;
      }
      flex: 1;
      padding: 20px 0;
    }

    &__headers-webhook {
      &__content {
        max-height: 300px;
        overflow-y: auto;
        padding-top: 10px;
        margin-bottom: 20px;
        &--input {
          width: 47%;
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
      margin: 20px auto;
      min-height: 200px;
    }
    &__action {
      button {
        width: 120px;
      }
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
