<template>
  <b-card
    no-body
    class="system-settings-card"
  >
    <!-- START: Action row -->
    <div class="m-2 d-flex justify-content-between table-actions">
      <b-button
        id="create-button"
        variant="primary"
        :class="{
          'icon-disabled': !hasSystemSettingsActions,
        }"
        @click="hasSystemSettingsActions && create()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Create New') }}</span>
      </b-button>
      <b-tooltip target="create-button">
        {{ hasSystemSettingsActions ? $t('Create') : $t('no-action-permission') }}
      </b-tooltip>
    </div>
    <!-- END: Action row -->
    <!-- START: Table -->
    <div class="m-2">
      <b-table
        ref="refTable"
        :busy="loading"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        class="table-responsive"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head()="{ label }">
          <span style="white-space: nowrap">{{ $t(label) }}</span>
        </template>

        <!-- START: Entity -->

        <template #cell(assignedTo)="{ item }">
          <template v-for="(entity, index) in item.assignedTo">
            <b-badge
              v-if="index <= 1"
              :key="entity.id"
              class="tw-mr-2"
              pill
              variant="secondary"
            >
              {{ $t(title(entity.tableKey)) }}
            </b-badge>
          </template>

          <b-badge
            v-if="item.assignedTo.length >= 3"
            pill
            variant="secondary"
          >
            +{{ item.assignedTo.length - 2 }}
          </b-badge>
        </template>

        <!-- START: Action -->
        <template #cell(version)="{ item }">
          <b-badge
            pill
            variant="secondary"
          >
            +{{ getActionTotal(item.assignedTo) }}
          </b-badge>
        </template>
        <!-- END: Action -->
        <!-- END: Entity -->

        <!-- START: Actions Column -->
        <template #cell(actions)="{ item }">
          <div class="row-flex-align-center">
            <feather-icon
              :id="`edit-row-${item.uuid}-preview-icon`"
              icon="EditIcon"
              size="16"
              :class="{
                'icon-disabled': !hasSystemSettingsActions,
              }"
              class="cursor-pointer"
              @click="hasSystemSettingsActions && edit(item.uuid)"
            />
            <b-tooltip
              placement="left"
              :title="hasSystemSettingsActions ? $t('Edit') : $t('no-action-permission')"
              :target="`edit-row-${item.uuid}-preview-icon`"
            />
            <div :id="`toggle-${item.uuid}-toggle-switch`">
              <b-form-checkbox
                v-model="item.isActive"
                switch
                class="toggle-with-disable-class"
                :disabled="!hasSystemSettingsActions"
                @input="toggleStatus(item.uuid, item.isActive)"
              />
            </div>

            <b-tooltip
              triggers="hover"
              placement="right"
              :title="hasSystemSettingsActions ? $t(`${item.isActive ? 'Disable' : 'Enable'} webhook`) : $t('no-action-permission')"
              :target="`toggle-${item.uuid}-toggle-switch`"
            />
          </div>
        </template>
        <!-- END: Action Column -->
      </b-table>
    </div>
    <!-- END: Table -->

    <!-- START: Pagination -->
    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handleCurrentPage"
    />
    <!-- END: Pagination -->

    <!-- START: Create webhook modal -->
    <webhooks-form
      :visible="showForm"
      :resource-id="resourceId"
      @close="showForm = false"
      @saved="fetchList()"
    />
    <!-- END: Create webhook modal -->
  </b-card>
</template>

<script>
import webhooksService from '@/services/webhooks'
import listMixinFactory from '@/mixins/list'
import WebhooksForm from '@/views/pages/SystemSettings/Integrations/Tabs/WebhooksForm.vue'
import { title } from '@/@core/utils/filter'

export default {
  name: 'WebHooksIntegrations',
  components: { WebhooksForm },
  mixins: [
    listMixinFactory({
      routeName: 'integrations-settings',
      service: webhooksService,
      tableColumns: [
        { key: 'name', label: 'Name', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'assignedTo', label: 'Entity', class: ' disabled' },
        { key: 'version', label: 'Actions Taken', class: 'disabled' },
        { key: 'url', label: 'URL', sortable: true },
        { key: 'actions', class: 'text-center disabled' },
      ],
      searchField: 'name',
      sort: {
        name: 'name',
        label: 'label',
      },
    }),
  ],

  data: () => ({
    showForm: false,

    title,
  }),

  methods: {
    getActionTotal(entities) {
      return entities.reduce((acc, curVal) => acc + curVal.actions.length, 0)
    },

    async toggleStatus(id, status) {
      const toggle = status ? 'on' : 'off'
      const { errors } = await this.$async(webhooksService.toggleStatus(id, toggle))
      if (errors) {
        this.fetchList()
        this.errors = this.$loadErrors(errors)
      }
    },
    create() {
      this.resourceId = null
      this.showForm = true
    },
  },
}
</script>

<style></style>
