<template>
  <div id="webhooks-container">
    <b-tabs
      class="tabs--inner"
      pills
      lazy
    >
      <b-tab
        :title="$t('Clients')"
        active
      >
        <Clients />
      </b-tab>
      <b-tab :title="$t('Webhooks')"><Webhooks /></b-tab>
      <b-tab :title="$t('Webhook Logs')">
        <b-card-text>
          <WebhookLogs />
        </b-card-text>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import Clients from '@/views/pages/SystemSettings/Integrations/Tabs/Clients.vue'
import Webhooks from '@/views/pages/SystemSettings/Integrations/Tabs/Webhooks.vue'
import WebhookLogs from '@/views/pages/SystemSettings/Integrations/Tabs/WebhookLogs.vue'

export default {
  components: { Clients, Webhooks, WebhookLogs },
  data: () => ({}),
}
</script>
