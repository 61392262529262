import API from '@axios'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/iam/clients'

export default {
  ...apiHelper(API_URL),
  getAllScopes() {
    return API.get(`${API_URL}/scopes`)
  },
  updateSecret(payload) {
    return API.put(`${API_URL}/${payload.id}/change_client_secret`, payload.data)
  },
}
