<template>
  <b-container fluid>
    <b-row align-h="around" align-v="center">

      <span class="tw-font-bold tw-text-2xs text-white ">{{ $t('Generate secret') }}?</span>

      <b-button id="create-button" variant="primary" @click="generateSecret">
        <span class="align-middle tw-text-xs">{{ $t('Auto Generate') }}</span>
      </b-button>
    </b-row>
  </b-container>
</template>

<script>
import crypto from 'crypto'

export default {
  methods: {
    generateSecret() {
      const secret = crypto.randomBytes(12).toString('hex')

      this.$emit('generate', secret)
    },
  },
}
</script>

<style></style>
