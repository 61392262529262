<template>
  <prozess-input
    v-model="val"
    :placeholder="placeholder"
    :append-icon-clickable="true"
    :type="inputType"
    :icon="icon"
    :field="inputName"
    :name="inputName"
    :error="error"
    :append-icon="showHideIcon"
    @input="handleInput"
    @appendIconClick="toggleShowHideText"
    @enter="$emit('enter')"
  />
</template>

<script>
import ProzessInput from '@core/components/ProzessInput.vue'

export default {
  components: { ProzessInput },
  props: {
    input: {
      type: String,
      default: null,
    },
    inputName: {
      type: String,
      required: true,
    },
    error: {
      type: String,
      default: null,
    },

    placeholder: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: null,
    },

  },

  data() {
    return {
      val: null,
      inputType: 'password',
    }
  },

  computed: {
    showHideIcon() {
      return this.inputType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  watch: {
    input: {
      handler() {
        this.$nextTick(() => { this.val = this.input })
      },

      immediate: true,
    },
  },

  methods: {
    toggleShowHideText(attrs) {
      const type = attrs.type
      type === 'password' ? this.inputType = 'text' : this.inputType = 'password'
    },

    resetStatus() {
      this.inputType = 'password'
    },

    handleInput() {
      this.$emit('update:input', this.val)
    },
  },
}
</script>

<style>

</style>
