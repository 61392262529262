<template>
  <b-card
    no-body
    class="system-settings-card"
  >
    <!-- START: action row -->
    <div class="m-2 d-flex justify-content-between table-actions">
      <b-button
        id="create-button"
        :class="{ 'icon-disabled': !hasSystemSettingsActions }"
        variant="primary"
        @click="hasSystemSettingsActions && create()"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span class="align-middle">{{ $t('Create New') }}</span>
      </b-button>
      <b-tooltip target="create-button">
        {{ hasSystemSettingsActions ? $t('Create') : $t('no-action-permission') }}
      </b-tooltip>
    </div>

    <!-- END: action row -->

    <!-- START: Form -->
    <sidebar-form
      :visible="showForm"
      :viewing="viewing"
      :resource-id="resourceId"
      @close="onCloseForm"
      @saved="fetchList()"
    />

    <client-form
      :resource-id="resourceId"
      :visible="secretShowForm"
      @close="onCloseForm"
      @saved="fetchList()"
    />

    <!-- END: Form -->

    <!-- START: Table -->
    <div class="m-2">
      <b-table
        ref="refTable"
        :busy="loading"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        class="table-responsive"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>
        <template #head()="{ label }">
          <span style="white-space: nowrap">{{ $t(label) }}</span>
        </template>

        <!-- START: secret template -->
        <template #cell(secret)="{ item }">
          <div class="col-flex-baseline">
            <span> ************</span>
            <feather-icon
              :id="`edit-secret-row-${item.uuidKey}-preview-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1 cursor-pointer"
              :class="{
                'icon-disabled': !hasSystemSettingsActions,
              }"
              @click="hasSystemSettingsActions && updateSecret(item.uuidKey)"
            />
            <b-tooltip
              placement="left"
              :target="`edit-secret-row-${item.uuidKey}-preview-icon`"
            >
              {{ hasSystemSettingsActions ? $t('Edit') : $t('no-action-permission') }}
            </b-tooltip>
          </div>
        </template>
        <!-- END: secret template -->

        <!-- START: Scopes template -->
        <template #cell(scopes)="{ item }">
          {{ displayScopes(item.scopes) }}
        </template>

        <!-- END: Scopes template -->

        <!-- START: Actions Column -->
        <template #cell(actions)="{ item }">
          <div class="text-nowrap">
            <feather-icon
              :id="`view-row-${item.uuidKey}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 cursor-pointer"
              @click="view(item.uuidKey)"
            />
            <b-tooltip
              placement="left"
              :title="$t('View')"
              :target="`view-row-${item.uuidKey}-preview-icon`"
            />
            <feather-icon
              :id="`edit-row-${item.uuidKey}-preview-icon`"
              icon="EditIcon"
              size="16"
              :class="{
                'icon-disabled': !hasSystemSettingsActions,
              }"
              class="mx-1 cursor-pointer"
              @click="hasSystemSettingsActions && edit(item.uuidKey)"
            />
            <b-tooltip
              placement="left"
              :title="hasSystemSettingsActions ? $t('Edit') : $t('no-action-permission')"
              :target="`edit-row-${item.uuidKey}-preview-icon`"
            />
            <feather-icon
              :id="`delete-row-${item.uuidKey}-preview-icon`"
              icon="TrashIcon"
              size="16"
              class="mx-1 cursor-pointer"
              :class="{
                'icon-disabled': !hasSystemSettingsActions,
              }"
              @click="hasSystemSettingsActions && handleDelete(item.uuidKey)"
            />
            <b-tooltip
              placement="left"
              :title="hasSystemSettingsActions ? $t('Delete') : $t('no-action-permission')"
              :target="`delete-row-${item.uuidKey}-preview-icon`"
            />
          </div>
        </template>
        <!-- END: Actions Column -->
      </b-table>
    </div>
    <!-- END: Table -->

    <!-- START: Pagination -->
    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handleCurrentPage"
    />
    <!-- END: Pagination -->
  </b-card>
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import { title } from '@/@core/utils/filter'
import clientsService from '@/services/clients'
import listMixinFactory from '@/mixins/list'
import SidebarForm from '@/views/pages/SystemSettings/Integrations/Tabs/ClientsForm.vue'
import ClientForm from '@/views/pages/SystemSettings/Integrations/Tabs/ClientSecretForm.vue'

export default {
  name: 'ClientIntegrations',
  components: { SidebarForm, ClientForm },
  mixins: [
    listMixinFactory({
      routeName: 'integrations-settings',
      service: clientsService,
      tableColumns: [
        { key: 'clientName', label: 'Name', sortable: true },
        { key: 'clientId', label: 'Client ID', sortable: true },
        { key: 'secret', label: 'Secret', class: ' disabled' },
        { key: 'scopes', label: 'Scopes', class: 'text-center disabled' },
        { key: 'actions', class: 'text-center disabled' },
      ],
      searchField: 'name',
      sort: {
        name: 'name',
        label: 'label',
      },
    }),
  ],

  data: () => ({
    resourceId: null,
    showForm: false,
    secretShowForm: false,
    viewing: false,
    title,
  }),

  methods: {
    create() {
      this.resourceId = null
      this.showForm = true
    },

    handleDelete(id) {
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(async result => {
        if (result.value) {
          this.deleteResource(id)
        }
      })
    },

    view(id) {
      this.showForm = true
      this.resourceId = id
      this.viewing = true
    },

    updateSecret(id) {
      this.resourceId = id
      this.secretShowForm = true
    },

    displayScopes(scopes) {
      return scopes.filter(scope => scope !== 'app.public').length
    },

    onCloseForm() {
      this.resourceId = null
      this.secretShowForm = false
      this.showForm = false
      this.viewing = false
    },
  },
}
</script>
