import API from '@axios'
import { apiHelper } from '../helpers/apiHelper'

const API_URL = '/api/webhooks/webhook'

export default {
  ...apiHelper(API_URL),
  getAllEntities() {
    return API.get('/api/webhooks/entities')
  },
  toggleStatus(id, status) {
    return API.put(`${API_URL}/${id}/${status}`)
  },

}
