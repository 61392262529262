<template>
  <prozess-sidebar-modal-wrapper
    id="client-form"
    :visible="visible"
    :editing="!!resourceId"
    :form-icon="formIcon"
    :form-title="formTitle"
    :loading="loading"
    :saving="saving"
    :is-view-only="viewing"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <prozess-input
        v-model="resource.clientName"
        :disabled="viewing"
        :placeholder="$t('Name')"
        icon="BriefcaseIcon"
        field="clientName"
        name="clientName"
        :error="$hasError('clientName')"
        @enter="save"
      />

      <prozess-input
        v-model="resource.clientId"
        :disabled="!!resourceId"
        :placeholder="$t('Client ID')"
        icon="HashIcon"
        field="clientId"
        name="clientId"
        :hint="`*${$t('Client ID must be unique')}`"
        :error="$hasError('clientId')"
        @enter="save"
      />

      <webhook-show-hide-input
        v-if="!resourceId"
        ref="secretInput"
        :input.sync="resource.clientSecret"
        :placeholder="$t('Secret')"
        input-name="clientSecret"
        icon="KeyIcon"
        :error="$hasError('clientSecret')"
        @enter="save"
      />

      <webhook-secret-auto-generator
        v-if="!resourceId"
        @generate="applySecret"
      />

      <!-- START: SCOPE checkbox -->
      <div class="checkbox-list-container">
        <div class="tw-my-2 checkbox-list-container__header">
          <b-form-checkbox
            v-model="isAllScopesSelected"
            :disabled="viewing"
            @change="handleSelectAllScopes"
          >Scopes</b-form-checkbox>
        </div>

        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="resource.scopes"
            :disabled="viewing"
            :aria-describedby="ariaDescribedby"
            class="checkbox-list-container__checkbox-container"
          >
            <b-form-checkbox
              v-for="scope in scopes"
              :key="scope.value"
              class="tw-my-2 "
              :value="scope"
            >{{ title(scope.text) }}</b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </div>
      <span v-if="$hasError('scopes')" class="invalid-feedback">{{
        $t($hasError('scopes'))
      }}</span>
      <!-- END: SCOPE checkbox -->

    </form>

  </prozess-sidebar-modal-wrapper>
</template>

<script>
import { title } from '@/@core/utils/filter'
import { clientSchema } from '@/schema/webhooks'
import ProzessSidebarModalWrapper from '@/@core/components/ProzessSidebarModalWrapper.vue'
import ProzessInput from '@core/components/ProzessInput.vue'
import WebhookShowHideInput from '@/components/Webhooks/WebhookShowHideInput.vue'
import WebhookSecretAutoGenerator from '@/components/Webhooks/WebhookSecretAutoGenerator.vue'
import clientsService from '@/services/clients'
import { scopesTransform } from '@/helpers/scopes'

const defaultResource = {
  clientName: '',
  clientId: '',
  clientSecret: '',
  scopes: [],
}
export default {
  name: 'ClientForm',
  components: {
    ProzessSidebarModalWrapper,
    ProzessInput,
    WebhookShowHideInput,
    WebhookSecretAutoGenerator,

  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },

    viewing: {
      type: Boolean,
      default: false,
    },

    resourceId: {
      type: [String, Number],
      default: null,
    },
  },

  data() {
    return {
      formId: 'clientForm',
      resource: this.$lodash.cloneDeep(defaultResource),
      isAllScopesSelected: false,
      scopes: [],
      loading: false,
      saving: false,
      errors: [],
      title,
    }
  },

  computed: {
    formIcon() {
      if (this.resourceId && this.viewing) return ''
      if (this.resourceId && !this.viewing) return 'EditIcon'
      return 'PlusIcon'
    },
    formTitle() {
      if (this.resourceId && this.viewing) return 'View Api Client'
      if (this.resourceId && !this.viewing) return 'Edit Api Client'
      return 'Add New Api Client'
    },

    scopePayload() {
      return this.resource.scopes.map(scope => scope.value)
    },
  },

  watch: {
    'resource.scopes': function (newVal, oldVal) {
      if (newVal.length !== this.scopes.length) this.isAllScopesSelected = false
      else this.isAllScopesSelected = true
    },

    visible: {
      handler(visible) {
        if (visible) {
          this.resetForm()
          this.getInitialData()
        }
      },
    },
  },

  created() {
    this.fetchAllScopes()
  },

  methods: {
    async getInitialData() {
      if (!this.resourceId) return
      this.loading = true
      const { response } = await this.$async(
        clientsService.getOne(this.resourceId),
      )

      const data = response.data
      this.resource = {
        clientName: data.clientName,
        clientId: data.clientId,
        scopes: scopesTransform(data.scopes),
      }

      this.loading = false
    },
    close() {
      this.resetForm()
      this.$emit('close')
    },

    resetForm() {
      this.isAllScopesSelected = false
      this.resource = this.$lodash.cloneDeep(defaultResource)
      this.errors = []
      if (!this.resourceId) this.$refs.secretInput.resetStatus()
    },
    async save() {
      this.resource.clientName = this.resource.clientName.trim()
      this.errors = await this.yupValidate(clientSchema, {
        ...this.resource,
        forUpdate: !!this.resourceId,
      })

      if (!this.errors.length) {
        this.saving = true

        const response = await this.$async(this.createOrUpdate(this.resource))
        const errors = response.errors
        const error = response.error

        const exception = this.$lodash.get(error, 'response.data.exception')

        if (exception?.includes('ClientIdAlreadyExistingException')) {
          this.saving = false
          return this.showWarning('Client Id already exist')
        }

        if (errors) this.errors = this.$loadErrors(errors)
        else {
          this.$emit('saved')
          this.close()
        }

        this.saving = false
      }
    },

    createOrUpdate(form) {
      const payload = !this.resourceId
        ? { ...form }
        : {
          clientName: form.clientName,
        }
      const data = { ...payload, scopes: this.scopePayload }
      return this.resourceId
        ? clientsService.update(this.resourceId, data)
        : clientsService.store(data)
    },

    applySecret(secret) {
      this.resource.clientSecret = secret
    },

    handleSelectAllScopes(val) {
      if (val) this.resource.scopes = this.scopes
      else this.resource.scopes = []
    },

    async fetchAllScopes() {
      this.loading = true
      const { response } = await this.$async(clientsService.getAllScopes())

      this.scopes = scopesTransform(response.data.map(scope => scope.scope))

      this.loading = false
    },
  },
}
</script>
