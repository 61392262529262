export const webHooksTabs = {
  clients: 'clients',
  webhooks: 'webhooks',
  webhooksLogs: 'webhooksLogs',
}

export const actions = [{
  text: 'Insert',
  formVal: 'CREATE',
  isAll: false,
  value: false,
}, {

  text: 'Update',
  formVal: 'UPDATE',
  isAll: false,
  value: false,
},
{
  text: 'Delete',
  formVal: 'DELETE',
  isAll: false,
  value: false,
}]
