import * as yup from 'yup'

const urlPattern = /(https?):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]/

const keyPattern = /^[a-z_0-9-\\]*$/
export const clientSchema = yup.object().shape(
  {
    clientName: yup.string().required().max(200, 'Max 200'),
    clientId: yup.string().required().min(8, 'Min 8').max(100, 'Max 100'),
    forUpdate: yup.boolean(),
    clientSecret: yup.string().when('forUpdate', {
      is: false,
      then: yup.string().required().min(8, 'Min 8').max(200, 'Max 200'),
      otherwise: yup.string(),
    }),
    scopes: yup.array().min(1, 'Required'),
  },
)

export const secretUpdateSchema = yup.object().shape({
  clientSecret: yup.string().required().min(8, 'Min 8').max(200, 'Max 200'),
  secretConfirmation: yup.string().oneOf([yup.ref('clientSecret'), null], 'Secret dont match'),
})

export const webhookSchema = yup.object().shape(
  {
    name: yup.string().required().max(100, 'Max 100'),
    description: yup.string().nullable(),
    webhookUrl: yup.string().required().matches(urlPattern, 'Invalid url, always include https:// at the start').max(500, 'Max 500'),
    entities: yup.array().min(1, 'Required'),
    headers: yup.array().of(yup.object().shape({
      key: yup.string().notRequired().max(30, 'Max 30').test(
        'header-key-validation',
        (value, { createError, path }) => {
          if (value) {
            const patternMatch = yup.string().matches(keyPattern).isValidSync(value)
            const minChar = yup.string().min(3).isValidSync(value)

            if (!patternMatch) {
              return createError({
                message: 'Invalid characters',
                path,
              })
            }

            if (!minChar) {
              return createError({
                message: 'Min 3',
                path,
              })
            }
          }
          return true
        },
      ),

      value: yup.string().max(30, 'Max 30').when('key', {
        is: val => val,
        then: yup.string().required().min(3, 'Min 3').max(200, 'Max 200'),
        otherwise: yup.string(),
      }),
    })),
  },
)

export default {
  clientSchema,
  secretUpdateSchema,
}
