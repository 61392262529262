<template>
  <prozess-sidebar-modal-wrapper
    id="client-form"
    :visible="visible"
    form-title="Edit Secret"
    form-icon="EditIcon"
    :loading="loading"
    :saving="saving"
    save-icon="SaveIcon"
    save-label="Save"
    @close="close"
    @submit="save"
  >
    <form
      :id="formId"
      ref="form"
      autocomplete="off"
      class="p-2"
      style="flex: 1"
      @submit.prevent
    >
      <webhook-show-hide-input
        ref="secret"
        :input.sync="form.clientSecret"
        :placeholder="$t('Enter new secret')"
        input-name="clientSecret"
        icon="KeyIcon"
        :error="$hasError('clientSecret')"
        @enter="save"
      />

      <webhook-show-hide-input
        ref="secretConfirm"
        :input.sync="form.secretConfirmation"
        :placeholder="$t('Confirm new secret')"
        input-name="secretConfirmation"
        icon="KeyIcon"
        :error="$hasError('secretConfirmation')"
        @enter="save"
      />
      <webhook-secret-auto-generator class="tw-pt-4" @generate="applySecret" />
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import WebhookSecretAutoGenerator from '@/components/Webhooks/WebhookSecretAutoGenerator.vue'
import ProzessSidebarModalWrapper from '@/@core/components/ProzessSidebarModalWrapper.vue'
import clientsService from '@/services/clients'
import { secretUpdateSchema } from '@/schema/webhooks'
import WebhookShowHideInput from '@/components/Webhooks/WebhookShowHideInput.vue'

const defaultForm = {
  clientSecret: null,
  secretConfirmation: null,
}

export default {
  components: {
    WebhookSecretAutoGenerator,
    ProzessSidebarModalWrapper,
    WebhookShowHideInput,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      formId: 'clientSecrentForm',
      form: this.$lodash.cloneDeep(defaultForm),
      loading: false,
      saving: false,
      errors: [],
    }
  },

  methods: {
    applySecret(clientSecret) {
      this.form = {
        clientSecret,
        secretConfirmation: clientSecret,
      }
    },

    close() {
      this.form = this.$lodash.cloneDeep(defaultForm)
      this.errors = []
      this.$refs.secret.resetStatus()
      this.$refs.secretConfirm.resetStatus()
      this.$emit('close')
    },

    async save() {
      this.errors = await this.yupValidate(secretUpdateSchema, this.form)

      if (!this.errors.length) {
        this.saving = true
        const { errors } = await this.$async(
          clientsService.updateSecret({
            id: this.resourceId,
            data: { newClientSecret: this.form.clientSecret },
          }),
        )
        if (errors) this.errors = this.$loadErrors(errors)
        else {
          this.$emit('saved')
          this.close()
        }
      }

      this.saving = false
    },
  },
}
</script>

<style></style>
